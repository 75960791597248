.consent-popup, .chatbase-popup {
    position: fixed;
    bottom: 20px;
    right: 64px;
    width: 450px;
    max-width: 100%;
    padding: 18px 40px 18px 16px;
    border-radius: 4px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e8e8e8;
    background-color: #fff;
    z-index: 10000;
}

.chatbase-popup {
    bottom: 65px;
    right: 64px;
    width: 450px;
    background-color: #eae9e9;
}
.chatbase-popup p {
    font-size: 14px;
    margin: 0;
}

.consent-popup p {
    font-size: 16px;
    margin: 0;
}
.consent-popup a, .chatbase-popup a {
    font-weight: bold;
    color: black;
    text-decoration: none;
}
a.terms {
    text-decoration: underline;
    font-size: 11px;
    color: black;
}
.consent-popup a:hover, a.terms:hover, .chatbase-popup a:hover {
    text-decoration: underline;
}
.consent-popup .close-popup, .chatbase-popup .close-popup {
    position: absolute;
    right: 16px;
    top: 20px;
}
.consent-popup .close-popup svg {
    width: 16px;
    height: 16px;
}

div.terms a {
    color: black;
    font-weight: bolder;
    text-decoration: underline;
}

/*
responsive
*/
@media (min-width: 766px) {
    .chatbase-popup {
        bottom: 120px;
    }
}
@media (max-width: 767px) {
    .consent-popup, .chatbase-popup {
        width: calc(100% - 16px);
        left: unset;
        right: unset;
        transform: translate(0, 0);
        margin-left: 8px;
        padding: 15px;
        bottom: 8px;
        padding-left: 16px;
        padding-right: 48px;
    }
    .chatbase-popup {
        left: unset;
        right: unset;
        bottom: 65px;
    }
    .consent-popup .close-popup, .chatbase-popup .close-popup {
        top: 50%;
        transform: translate(0, -50%);
    }
    .consent-popup p, .chatbase-popup p {
        font-size: 12px;
    }

    .section + .consent-popup, .section + .chatbase-popup {
        bottom: 80px;
    }
}