.whatsapp-sticky-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    transition: transform 0.3s ease;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.2));
}

.whatsapp-sticky-button:hover {
    transform: scale(1.1);
}

.whatsapp-sticky-button img {
    border-radius: 50%;
} 