ul.logos {
    margin: 0;
    list-style: none;
    padding: 12px 16px;
    height: 92px;
}
ul.logos li {
    display: inline-block;
    vertical-align: top;
    height: 100%;
}
ul.logos li:first-of-type {
    padding-right: 16px;
}
ul.logos li:last-of-type {
    padding-left: 16px;
    border-left: solid 1px #b0b0b0;
    max-height: 50px;
}
nav#nav.pelayohome ul.logos li:last-of-type {
    padding-left: 16px;
    border-left: unset;
    max-height: 50px;
}

ul.logos li a {
    display: block;
    text-decoration: none;
    width: inherit;
    height: inherit;
}

ul.logos li img {
    width: auto;
    height: 100%;
    vertical-align: unset !important;
    max-height: 45px;
}
nav#nav.bauhaus {
    background-color: #ee1f26;
}
nav#nav.pelayohome {
    background-color: #00568b;
}
nav#nav.gesvalt {
    background-color: #0075c9;
}
nav#nav.pelayohome   #by-jobin-li img,nav#nav.pelayohome  div.navbar-header > a > img{
    right: 1.2em !important;
    position: absolute !important;
    height: unset !important;
    width: 15%;
    margin-top: unset !important;
    top: 50%;
    transform: translateY(-50%);
}
#by-jobin-li img, #nav > div.navbar-header > a > img{
    height: 2em !important;
    margin-top: 0.3em !important;
    right: 0.2em;
    width: 11em;
    position: absolute;
}

#nav > div.navbar-header > a > img{
    margin-top: 0.5em !important;
}

#service-price > div > div > div.col-md-6 {
    margin-top: 1.4em !important;
}

@media (min-width: 768px) {
    #by-jobin-li img, #nav > div.navbar-header > a > img{
        margin-top: 0.9em !important;
        right: 1.2em;
    }
}

@media (max-width: 364px) {
    #by-jobin-li img, #nav > div.navbar-header > a > img{
        height: 1.7em !important;
        margin-top: 0.8em !important;
        right: -0.2em;
        width: 9em;
    }
    .jobin-app-navbar .navbar-header .logos img{
        height: 2em !important;
    }
}
@media (max-width: 264px) {
    #by-jobin-li img {
        display: none;
    }
}
