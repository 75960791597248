.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 32px;
    width: 468px;
    min-height: 545px;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #d6d6d6;
    background-color: #fff;
}

.modal a {
    color: var(--aqua-jobin-darker);
    text-decoration: none;
}
.modal a:hover {
    text-decoration: underline;
}

.modal .close-modal {
    position: absolute;
    top: 24px;
    right: 24px;
}
.modal .close-modal svg:hover {
    cursor: pointer;
}
.modal .close-modal svg:hover #circle {
    fill: #f2f2f2;
}

.modal .title {
    font-weight: bold;
    margin-top: 74px;
    margin-bottom: 8px;
}
.modal .title.terms {
    margin-top: 64px;
}

.modal .excerpt {
    font-size: 14px;
    margin-bottom: 32px;
}

.modal .input-group {
    margin-bottom: 16px;
}
.modal .input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 4px;
    border: solid 1px var(--white-two);
    background-color: #fff;
    font-size: 16px;
    -webkit-appearance: none;
}
.modal .input:focus,
.modal .phone-container select:focus {
    border: solid 1px var(--greyish-brown);
    outline: none;
}
.modal .input.error {
    border: solid 2px #fa504c;
}

.modal .phone-container {
    position: relative;
}
.modal .phone-container select {
    background: #fff url("../assets/select-arrow.svg") no-repeat 65px center;
    border-radius: 4px;
    border: solid 1px var(--white-two);
    top: 12px;
    left: 12px;
    font-size: 16px;
    appearance: none;
    padding: 0 16px;
    width: 102px;
    height: 48px;
}

.modal span.error {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #fa504c;
}

.modal .code-elem {
    width: 56px;
    height: 56px;
    border-radius: 4px;
    border: solid 1px var(--white-two);
    background-color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 0 var(--greyish-brown);
}
.modal .code-elem:focus {
    border: solid 1px var(--greyish-brown);
    outline: none;
}
.modal .code-elem + .code-elem {
    margin-left: 12px;
}
.modal input[type="number"]::-webkit-inner-spin-button,
.modal input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal .one-row-button {
    position: absolute;
    width: calc(100% - 64px);
    bottom: 20px;
}
.one-row-button.conditions {
    position: initial;
    width: calc(100% - 64px);
    margin: 10px auto 60px;
    bottom: 20px;
}
.one-row-button.terms {
    position: initial;
    margin: 10px auto 10px;
    bottom: 13em;
    text-align: center;
}

.modal .one-row-button label {
    display: block;
    margin-bottom: 20px;
    font-size: 13px;
    color: var(--warm-grey);
}
.modal .one-row-button label > * {
    vertical-align: bottom;
}
.modal .one-row-button label svg {
    margin-right: 8px;
}
.modal .one-row-button button {
    width: 100%;
    height: 48px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-align: center;
    color: black;
    background-color: var(--aqua-jobin-darker);
    border-radius: 4px;
    padding: 0 12px;
}
.modal .one-row-button button:disabled {
    opacity: 0.5;
    cursor: auto;
}


.one-row-button.conditions {
    bottom: 6em;
}

input#terms-confirmed,input#sharing-data-confirmed {
    margin-bottom: 0.2em;
    margin-right: 0.6em;
}

.modal .bottom-controls {
    position: absolute;
    width: calc(100% - 64px);
}

.modal.error {
    text-align: center;
}
.modal.error .title,
.modal.noJobers .title {
    margin-top: 24px;
}
.modal.error img {
    width: 160px;
    height: 160px;
    margin-top: 44px;
}
.modal.error .one-row-button button {
    background-color: var(--coral);
}

.modal.noJobers {
    padding-top: 64px;
}
.modal.noJobers img {
    max-width: 100%;
    width: 240px;
    height: 160px;
    margin-left: -32px;
}
.modal.noJobers .title,
.modal.noJobers .excerpt {
    text-align: center;
}

.modal.jobinPro {
    text-align: center;
}
.modal.jobinPro .one-row-button button {
    background-color: var(--coral);
}

.modal img.img-secure {
    height: 8em;
    margin-top: -2em;
}

.modal a.c-black {
    color: black;
}

p.excerpt.link-conditions a {
    font-weight: bolder;
    text-decoration: underline;
}

/*
responsive
*/
@media (max-width: 767px) {
    .overlay {
        background: #202020;
    }
    .modal {
        width: calc(100% - 16px);
        margin-left: 8px;
        left: unset;
        transform: translate(0, -50%);
        padding: 20px 24px;
    }
    .modal .close-modal {
        top: 16px;
        right: 16px;
    }
    .modal .title {
        font-size: 22px;
        margin-top: 40px;
    }
    .modal .phone-container select {
        margin-bottom: 8px;
    }
    .modal .one-row-button {
        width: calc(100% - 48px);
    }

    .modal .code-container {
        display: flex;
        justify-content: space-between;
    }
    .modal .code-elem {
        width: auto;
        height: 56px;
        font-size: 27px;
        border: solid 1px var(--greyish-brown);
    }
    .modal .code-elem:focus {
        width: auto;
        height: 56px;
        font-size: 27px;
        border: solid 1px var(--);
    }

    .modal .code-elem + .code-elem{
        margin-left: 0;
    }
    .modal .bottom-controls {
        width: calc(100% - 48px);
        box-shadow: unset;
    }

    .modal.error .title {
        margin-top: 24px;
    }
}
